@charset "utf-8";

/** General Setting **/
body {
    background: #eee;
    color: #000;
    font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
a {
    color: #003d4c;
    text-decoration:none;
}
a:hover {
    color: #367889;
    text-decoration: underline;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}
.clearfix {
        min-height: 1px;
}

.inner_container {
    width: 950px;
    margin: 0 auto;
}
/** Header Setting **/
#header {
    color: #fff;
    background-color: #333;
    text-align: center;
    margin-bottom: 20px;
}
#header_logo {
    float: left;
}
#header_logo a {
    color: #fff;
    padding: 15px 10px;
    font-size: 25px;
    display: block;
    font-family: 'Handlee', cursive;
    font-family: 'Raleway Dots', cursive;
}
#header_logo a:hover {
    color: #ddd;
    text-decoration: none;
}
#header_about {
    padding: 22px 0 0 10px;
    float: left;
    color: #ddd;
    font-size: 12px;
}

/** Content Setting **/
#form_area {
    height: 400px;
    width: 450px;
    padding: 25px;
    float: left;
}
#input_box {
    text-align: center;
    margin-top: 150px;
    font-size: 16px;
    font-weight: bold;
}

#brand_box {
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
}

#q {
    width: 50px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 10px;
}
#submit_box {
    margin-top: 20px;
    text-align: right;
}
#submit_button {
    width: 200px;
    height: 40px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    background-color: #e3156b;
    font-size: 15px;
}
#submit_button:hover {
    background-color: #ed3e87;
}
#result_area {
    width: 350px;
    height: 420px;
    padding: 25px;
    margin: 50px 25px;
    float: left;
    background: #fff;
    border-radius: 10px;
}
#result_title {
    font-size: 20px;
    margin-bottom: 40px;
}
#result_area dt {
    float: left;
    width: 165px;
    padding: 5px 0 5px 10px;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #aaa;
}
#result_area dd {
    margin-left: 165px;
    width: 165px;
    padding: 5px 10px 5px 0;
    text-align: right;
    font-size: 14px;
    border-bottom: 1px solid #aaa;
}

#banner_box {
    height: 100px;
    padding:20px;
    text-align:center;
}